<template>
  <div class="bodycont">

    <comHeader />
    <div class="infobanner"></div>
    <div class="main">
      <div class="news_list">
           <infonavtab  :cindex='cindex' />
      </div>

       <div  class="infocont infocont4">
          <p>您好，您可以留下联系方式，工作人员会第一时间给予您回复！</p>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名:">
            <el-input v-model="form.umUsername"></el-input>
          </el-form-item>
          <el-form-item label="单位:">
            <el-input v-model="form.umDept"></el-input>
          </el-form-item>
          <el-form-item label="电话:">
            <el-input v-model="form.umTel"></el-input>
          </el-form-item>
          <el-form-item label="邮箱:">
            <el-input v-model="form.umEmail"></el-input>
          </el-form-item>
          <el-form-item label="留言:">
            <el-input type="textarea" v-model="form.umMessage"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"  @click="onSubmit">提交</el-button>
            <el-button>取消</el-button>
          </el-form-item>
        </el-form>
       </div>
   </div>

  <bNav />

  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import infonavtab from './components/navtababout.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      infonavtab,
      comHeader,
      bNav,
      navTitle,
    },
    data() {
      return {
         form: {
            umMessage:"",
            umTel:"",
            umEmail:"",
            umDept:"",
            umUsername:"",
          },
          cindex:3,
      }
    },
    created() {

    },
    mounted() {

    },
    methods: {
       onSubmit() {
             if(sessionStorage.getItem('isLogin')!=1){
               this.$message({
                   type: "error",
                   message: "请前往登陆！",
                 });
               return
             }
             if(!this.form.umMessage){
               this.$message({
                   type: "error",
                   message: "留言不能为空！",
                 });
               return
             }
             if(!this.form.umTel){
               this.$message({
                   type: "error",
                   message: "联系方式不能为空！",
                 });
               return
             }

             postRequest(api.saveMessage, this.form).then(res => {
               this.$message({
                   type: "success",
                   message: "提交留言成功,请耐心等待工作人员回复！",
                 });
               })
               this.form = {
                   umMessage:"",
                   umTel:"",
                   umEmail:"",
                   umDept:"",
                   umUsername:"",
               }
       },
      //切换目录
      changeIndex(item) {
        this.cindex = item.id
      },
      //详情页面
      tourl(item) {
        this.$router.push({
          path: '/newsdetails',
          query: {
            id: item.id,
            url: 'news',
            name: '成果新闻'
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-form{
        width: 80%;
        margin: 0 auto;
         box-shadow: 0px 5px 14px 0 #ddd;
         padding: 30px;
  }
 .news_list {
   width: 200px;
   overflow: hidden;
   float: left;
 }

  .bodycont {
    margin: 0px;
    background-size: 100% 100%;
    background-attachment: fixed;
  }

  .main {
    width:1190px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
  }

  .infocont {
    width: 80%;

  }

  .infocont4{
    min-height:73vh;

    p{
      margin-bottom: 30px;
      text-align: center;
      margin-top: 30px;
    }
  }
   .infobanner{
   background: url('../../assets/images/banner/about.png') center center no-repeat;
   width: 100%;
   height:350px;
   background-size:cover;
 }
</style>
